<template>
  <div class="mod-config">
     
     <el-form :inline="true" :model="dataForm" @keyup.enter.native="currentChangeHandle(1)">
      <table class="detailTable_search" style="width: 100%">
        <tr>
          <td class="titleTd width80px">{{ $t('job.beanName') }}</td>
          <td class="valueTd">
            <el-input v-model="dataForm.beanName" :placeholder="$t('job.beanName')" clearable></el-input>
          </td>
          <td class="titleTd width80px">{{ $t('job.methodName') }}</td>
          <td class="valueTd">
            <el-input v-model="dataForm.methodName" :placeholder="$t('job.methodName')" clearable></el-input>
          </td>
          <td class="titleTd width80px">{{ $t('job.remark') }}</td>
          <td class="valueTd">
            <el-input v-model="dataForm.remark" :placeholder="$t('job.remark')" clearable></el-input>
          </td>
        </tr>
      </table>
    </el-form>
    <div class="wxts_msg_search" >
      <div class="f_t">
        <el-button-group  >
          <el-button

            type="success"
            @click="currentChangeHandle(1)"
            :loading="searchLoading"
            size="mini"
          >{{ searchLoading ? $t('querying') : $t('query') }}</el-button
          >
          <el-button
            v-if="isAuth('job:schedule')"
            type="primary"
            @click="addOrUpdateHandle()"
            size="mini"
          >{{ $t('add') }}</el-button
          >
          <el-button
            v-if="isAuth('job:schedule')"
            type="danger"
            @click="deleteHandle()"
            :disabled="dataListSelections.length <= 0"
            size="mini"
          >{{ $t('deleteBatch') }}</el-button
          >
          <el-button
            v-if="isAuth('job:schedule')"
            type="danger"
            @click="pauseHandle()"
            :disabled="dataListSelections.length <= 0"
            size="mini"
          >{{ $t('job.pauseBatch') }}</el-button
          >
          <el-button
            v-if="isAuth('job:schedule')"
            type="danger"
            @click="resumeHandle()"
            :disabled="dataListSelections.length <= 0"
            size="mini"
          >{{ $t('job.recoverBatch') }}</el-button
          >
          <el-button
            v-if="isAuth('job:schedule')"
            type="danger"
            @click="runHandle()"
            :disabled="dataListSelections.length <= 0"
            size="mini"
          >{{ $t('job.runBatch') }}</el-button
          >
          <el-button
            v-if="isAuth('job:schedule')"
            type="success"
            @click="logHandle()"
            size="mini"
          >{{ $t('job.logList') }}</el-button
          >
        </el-button-group>
      </div>
    </div>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;"
    >
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50"
      >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        width="60"
        type="index"
        :label="$t('job.NO')"
        v-if="false"
      >
      </el-table-column>
      <el-table-column
        prop="beanName"
        header-align="center"
        align="center"
        :label="$t('job.beanName')"
        min-width="20%"
      >
      </el-table-column>
      <el-table-column
        prop="methodName"
        header-align="center"
        align="center"
        :label="$t('job.methodName')"
        min-width="20%"
      >
      </el-table-column>
      <el-table-column
        prop="params"
        header-align="center"
        align="center"
        :label="$t('job.parameter')"
        min-width="20%"
      >
      </el-table-column>
      <el-table-column
        prop="cronExpression"
        header-align="center"
        align="center"
        :label="$t('job.cronExpression')"
        min-width="25%"
      >
      </el-table-column>
      <el-table-column
        prop="remark"
        header-align="center"
        align="center"
        :label="$t('job.remark')"
        min-width="20%"
      >
      </el-table-column>
      <el-table-column
        prop="status"
        header-align="center"
        align="center"
        :label="$t('job.state')"
        width="80px"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 0" size="small">{{ $t('job.normal') }}</el-tag>
          <el-tag v-else size="small" type="danger">{{ $t('job.pause') }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="300"
        :label="$t('job.operate')"
      >
        <template slot-scope="scope">
          <el-button-group>
            <el-button
              v-if="isAuth('job:schedule')"
              type="primary"
              plain
              size="small"
              @click="addOrUpdateHandle(scope.row.jobId)"
              >{{ $t('update') }}</el-button
            >
            <el-button
              v-if="isAuth('job:schedule')"
              class="del-button"
              type="danger"
              plain
              size="small"
              @click="deleteHandle(scope.row.jobId)"
              >{{ $t('delete') }}</el-button
            >
            <el-button
              v-if="isAuth('job:schedule')"
              class="greyButton"
              plain
              size="small"
              @click="pauseHandle(scope.row.jobId)"
              >{{ $t('job.pause') }}</el-button
            >
            <el-button
              v-if="isAuth('job:schedule')"
              type="primary"
              plain
              size="small"
              @click="resumeHandle(scope.row.jobId)"
              >{{ $t('job.recover') }}</el-button
            >
            <el-button
              v-if="isAuth('job:schedule')"
              class="yellowButton"
              type="info"
              plain
              size="small"
              @click="runHandle(scope.row.jobId)"
              >{{ $t('job.run') }}</el-button
            >
            <el-button
              v-if="isAuth('job:schedule')"
              class="yellowButton"
              type="primary"
              plain
              size="small"
              @click="logHandle(scope.row.jobId)"
              >{{ $t('job.logList') }}</el-button
            >
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalCount"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
    <!-- 弹窗, 日志列表 -->
    <log v-if="logVisible" ref="log"></log>
  </div>
</template>

<script>
import AddOrUpdate from "./schedule-add-or-update";
import Log from "./schedule-log";
import { getList,deleteJob,pause,resume,run}  from '@/api/job/schedule'
export default {
  data() {
    return {
      searchLoading: false,
      dataForm: {
        beanName: "",
        methodName: "",
        remark: ""
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      logVisible: false
    };
  },
  components: {
    AddOrUpdate,
    Log
  },
  activated() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.searchLoading = true; 
      getList({
          page: this.pageIndex,
          limit: this.pageSize,
          beanName: this.dataForm.beanName,
          methodName: this.dataForm.methodName,
          remark: this.dataForm.remark
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.dataList = data.data.list;
            this.totalCount = data.data.totalCount;
            if(data.data.page != this.pageIndex) {
              this.pageIndex = data.data.page
            }
          } else {
            this.dataList = []
            this.totalCount = 0
            this.pageIndex = 1
          } 
        })
        .finally(() => {
          this.dataListLoading = false;
          this.searchLoading = false;
        });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      var ids = id
        ? [id]
        : this.dataListSelections.map(item => {
            return item.jobId;
          });
      this.$confirm(this.$t('job.tip6')+`${id ? this.$t('delete') : this.$t('job.deleteBash')}`+this.$t('operate'), this.$t('job.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: "warning"
      }).then(() => { 
        deleteJob(ids).then(({ data }) => {
              if (data && data.code === 0) {
                this.$message({
                  message: this.$t('success'),
                  type: "success",
                  duration: 500,
                  onClose: () => {
                    this.getDataList();
                  }
                });
              } else {
                this.$message.error(data.msg);
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    // 暂停
    pauseHandle(id) {
      var ids = id
        ? [id]
        : this.dataListSelections.map(item => {
            return item.jobId;
          });
      this.$confirm(this.$t('job.tip6')+`${id ? this.$t('job.pause') : this.$t('job.pause')}`+this.$t('operate'), this.$t('job.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: "warning"
      })
        .then(() => {
       
          pause(ids).then(({ data }) => {
              if (data && data.code === 0) {
                this.$message({
                  message: this.$t('success'),
                  type: "success",
                  duration: 500,
                  onClose: () => {
                    this.getDataList();
                  }
                });
              } else {
                this.$message.error(data.msg);
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    // 恢复
    resumeHandle(id) {
      var ids = id
        ? [id]
        : this.dataListSelections.map(item => {
            return item.jobId;
          });
      this.$confirm(this.$t('job.tip6')+`${id ? this.$t('job.recover') : this.$t('job.recoverBash')}`+this.$t('operate'), this.$t('job.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: "warning"
      })
        .then(() => {
         
          resume(ids).then(({ data }) => {
              if (data && data.code === 0) {
                this.$message({
                  message: this.$t('success'),
                  type: "success",
                  duration: 500,
                  onClose: () => {
                    this.getDataList();
                  }
                });
              } else {
                this.$message.error(data.msg);
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    // 立即执行
    runHandle(id) {
      var ids = id
        ? [id]
        : this.dataListSelections.map(item => {
            return item.jobId;
          });
      this.$confirm(
          this.$t('job.tip6')+`${id ? this.$t('job.run') : this.$t('job.runBash')}`+this.$t('operate'),
          this.$t('job.title'),
        {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: "warning"
        }
      )
        .then(() => {
      
          run(ids).then(({ data }) => {
              if (data && data.code === 0) {
                this.$message({
                  message: this.$t('success'),
                  type: "success",
                  duration: 500,
                  onClose: () => {
                    this.getDataList();
                  }
                });
              } else {
                this.$message.error(data.msg);
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    // 日志列表
    logHandle(id) {
      var ids = id
        ? [id]
        : this.dataListSelections.map(item => {
            return item.jobId;
          });
      this.logVisible = true;
      this.$nextTick(() => {
        this.$refs.log.init(ids);
      });
    }
  }
};
</script>
<style scoped>
.el-button--small,
.el-button--small.is-round {
  padding: 3px 6px;
}
.el-tag--small,
.el-button--small.is-round {
  padding: 3px 6px;
  display: inline;
}

</style>
